import { createWebHistory, createRouter } from "vue-router";
import { getAuth } from "firebase/auth"
import store from '@/store'
import Home from "@/pages/Home.vue";
import About from "@/pages/About.vue";
import Courses from "@/pages/Courses.vue";
import Enrolled from "@/pages/Enrolled.vue";
import Login from "@/pages/Login.vue";
import Login2 from "@/pages/Login2.vue";
import ForgotPassword from "@/pages/ForgotPassword.vue";
import Register from "@/pages/Register.vue";
import UserProfile from "@/pages/UserProfile.vue";
import PageNotFound from "@/pages/PageNotFound.vue";
import Course from "@/pages/Course.vue";
import Checkout from "@/pages/Checkout.vue";
import Checkout2 from "@/pages/Checkout2.vue";
import Learn from "@/pages/Learn.vue";
import Admin from "@/pages/Admin.vue";
import PrivacyPolicy from "@/pages/PrivacyPolicy.vue";
import UserPolicy from "@/pages/UserPolicy.vue";
import Finish from "@/pages/Finish.vue";
import PurchaseHistory from "@/pages/PurchaseHistory.vue";
import Marketing from "@/pages/Marketing.vue";

import { httpsCallable } from "firebase/functions"
import { functions } from "@/firebase/firebaseInit.js"
import axios from 'axios'

const auth = getAuth()

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      let user = localStorage.getItem('user')
      if(user){
        next('/')
      }
      next()
    }
  },
  // {
  //   path: "/login2",
  //   name: "Login2",
  //   component: Login2,
  //   beforeEnter: (to, from, next) => {
  //     let user = localStorage.getItem('user')
  //     if(user){
  //       next('/')
  //     }
  //     next()
  //   }
  // },
  {
    path: "/register",
    name: "Register",
    component: Register,
    beforeEnter: (to, from, next) => {
      let user = localStorage.getItem('user')
      if(user){
        next('/')
      }
      next()
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    beforeEnter: (to, from, next) => {
      let user = localStorage.getItem('user')
      if(user){
        next('/')
      }
      next()
    }
  },
  {
    path: "/user-profile/:id",
    name: "UserProfile",
    component: UserProfile,
    beforeEnter: (to, from, next) => {
      const id = to.params.id
      let user = localStorage.getItem('user')
      if(user){
        user = JSON.parse(user)
        if(user.uid==id){
          next()
        }
      }
      next('/')
    }
  },
  {
    path: "/purchase-history",
    name: "PurchaseHistory",
    component: PurchaseHistory,
    beforeEnter: (to, from, next) => {
      let user = localStorage.getItem('user')
      if(user){
        next()
      }
      next('/')
    }
  },
  {
    path: "/courses",
    name: "Courses",
    component: Courses,
  },
  {
    path: "/courses/:id",
    name: "Course",
    component: Course,
  },
  {
    path: "/courses/:id/checkout",
    name: "Checkout",
    component: Checkout,
    beforeEnter: (to, from, next) => {
      let buy_course = localStorage.getItem('buy_course')
      if(buy_course){
        buy_course = JSON.parse(buy_course)
        const course_id = to.params.id;
        if(buy_course.includes(course_id)){
          router.push({ name: 'Learn', params: { id: course_id } })
        }
      }
      next()
    }
  },
  // {
  //   path: "/courses/:id/checkout2",
  //   name: "Checkout2",
  //   component: Checkout2,
  //   beforeEnter: (to, from, next) => {
  //     let buy_course = localStorage.getItem('buy_course')
  //     if(buy_course){
  //       buy_course = JSON.parse(buy_course)
  //       const course_id = to.params.id;
  //       if(buy_course.includes(course_id)){
  //         router.push({ name: 'Learn', params: { id: course_id } })
  //       }
  //     }
  //     next()
  //   }
  // },
  {
    path: "/enrolled",
    name: "Enrolled",
    component: Enrolled,
  },
  {
    path: "/courses/:id/lectures",
    name: "Learn",
    component: Learn,
    beforeEnter: (to, from, next) => {
      let user = localStorage.getItem('user')
      let buy_course = localStorage.getItem('buy_course')
      if(user && buy_course){
        buy_course = JSON.parse(buy_course)
        const course_id = to.params.id;
        if(buy_course.includes(course_id)){
          next()
        }
      }
      next('/')
    }
  },
  { 
    path: "/admin", 
    name: "Admin",
    component: Admin,
    beforeEnter: (to, from, next) => {

      auth.onAuthStateChanged(function(user){
        if (user) {
          user.getIdTokenResult()
          .then((data)=>{
            if(data.claims.admin){
              next()
            }
            next('/')
          }).catch(()=>{
            next('/')
          })
        }
        else{
          next('/')
        }
      })
      
      // setTimeout(function(){ 
      //   if(auth.currentUser.admin){
      //     next()
      //   }
      //   next('/')
      // }, 500);
    }
  },
  { 
    path: "/privacy-policy", 
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },
  { 
    path: "/user-policy", 
    name: "UserPolicy",
    component: UserPolicy
  },
  { 
    path: "/marketing", 
    name: "Marketing",
    component: Marketing
  },
  // { 
  //   path: "/finish", 
  //   name: "finish",
  //   component: Finish
  // },
  { 
    path: "/:pathMatch(.*)",
    redirect: '/'
    // name: "PageNotFound",
    // component: PageNotFound,
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'Register'){
    localStorage.removeItem('redirect_url');
    sessionStorage.removeItem('google_popup_blocked')
  }

  const seller = from.query.seller;
  if(seller && !to.query.seller){
    to.query.seller = seller
    next({ path: to.path, query: to.query });
  }else{
    next()
  }
})

router.afterEach((to, from) => {
  // if (to.name == 'Learn'){
  //   let redirect = localStorage.getItem(`learn_${to.params.id}`);
  //   if(!redirect){
  //     localStorage.setItem(`learn_${to.params.id}`, "true")
  //     location.reload();
  //   }
  // }

  
  // fb 轉換資料
  const crypto = require('crypto');
  function hashEmail(email) {
    return crypto
      .createHash('sha256')
      .update(email.toLowerCase())
      .digest('hex');
  }
  const FB_TOKEN = "EAAFq6qZCh79UBAETRH31bYKYfTPx5GcxPzNLbDJ0dUjb02zL0docXZCuNqTiUIltkldhawDdl1cNWR0RjN9AegL3As0kMjdi7b0RrCMPHGAxNqnGYEhDOiqZCPaEiU1hiWJyuDa79tmm0sOzbJi3VNBJ3EZBWpUbsLC04IHXSL9kpO1IR5rV15ZA1EtjRCd8ZD"
  const FB_API_VERSION = "v17.0"
  const FB_PIXEL_ID = "1324532015152356"
  const now = new Date();
  const timestamp = Math.floor(now.getTime() / 1000);
  let user = localStorage.getItem('user')
  let user_email = undefined
  let hash_email = undefined
  if(user){
    user = JSON.parse(user)
    user_email = user["email"]
    hash_email = hashEmail(user_email)
  }
  const fb_data = {
    "data": [
      {
        "event_name": "Purchase",
        "event_time": timestamp,
        "action_source": "website",
        "event_id": "",
        "user_data": {
          "em": [
            hash_email
          ]
        },
        "custom_data": {
          "currency": "TWD",
          "value": "0"
        }
      }
    ]
  }
  // 


  let bill = localStorage.getItem('bill')
  let python_ai_package = undefined
  let web_package = undefined
  if(bill){
    bill = JSON.parse(bill)
    if(bill["yCC2Q8WFsc9Av52myZBh"]){
      python_ai_package = true
    }else if(bill["dmS4146YJasRcWA3NURx"]){
      web_package = true
    }
  }

  function currentTime() {
    process.env.TZ = 'Asia/Taipei';
    var date = new Date();
    var mm = date.getMonth() + 1;
    var dd = date.getDate();
    var hh = date.getHours();
    var mi = date.getMinutes();
    var ss = date.getSeconds();

    return [date.getFullYear(), "/" +
        (mm > 9 ? '' : '0') + mm, "/" +
        (dd > 9 ? '' : '0') + dd, " " +
        (hh > 9 ? '' : '0') + hh, ":" +
        (mi > 9 ? '' : '0') + mi, ":" +
        (ss > 9 ? '' : '0') + ss
    ].join('');
  };

  function buyInHour(id){
    let in_hour = false
    if(bill[id]["time"]){
      let buy_hours = (new Date(currentTime())-new Date(bill[id]["time"]))/1000/60/60
      if(buy_hours<1){
        in_hour = true
      }
    }
    return in_hour
  }

  function BuyPythonAiPackage(){
    // python AI 組合包
    if(buyInHour("yCC2Q8WFsc9Av52myZBh")){
      createConversion(bill["yCC2Q8WFsc9Av52myZBh"])
      gtag('event', 'conversion', {
        'send_to': 'AW-11171719053/pdlqCMDX0K8YEI3Pi88p',
        'value': bill["yCC2Q8WFsc9Av52myZBh"]["price"],
        'currency': 'TWD',
        'transaction_id': bill["yCC2Q8WFsc9Av52myZBh"]["order_id"]
      }); 
      gtag('event', '購買_pythonAI組合包');
    }
  }

  function BuyWebPackage(){
    // web 組合包
    if(buyInHour("dmS4146YJasRcWA3NURx")){
      createConversion(bill["dmS4146YJasRcWA3NURx"])
      gtag('event', 'conversion', {
        'send_to': 'AW-11171719053/cOugCLbrn6MZEI3Pi88p',
        'value': bill["dmS4146YJasRcWA3NURx"]["price"],
        'currency': 'TWD',
        'transaction_id': bill["dmS4146YJasRcWA3NURx"]["order_id"]
      }); 
      gtag('event', '購買_網頁開發組合包');
    }
  }

  function createConversion(bill){
    // google
    const google_data = {
      order_id: bill["order_id"],
      price: bill["price"],
      order_time: bill["time"],
      conversion_time: currentTime()
    }
    const create = httpsCallable(functions, 'createConversion');
    create(google_data)

    gtag('set', 'user_data', {
      "email": user_email
    });



    // fb
    fb_data["data"][0]["event_id"] = bill["order_id"]
    fb_data["data"][0]["custom_data"]["value"] = bill["price"]
    axios.post(`https://graph.facebook.com/${FB_API_VERSION}/${FB_PIXEL_ID}/events?access_token=${FB_TOKEN}`, fb_data);
  }

  if (to.path == '/courses/oMEJkCfYvORt8uAKhb7t/lectures') {
    // 20天全面了解python
    // console.log('o')
    // 確認是否買組合包
    if(python_ai_package){
      BuyPythonAiPackage()
    }else{
      if(buyInHour("oMEJkCfYvORt8uAKhb7t")){
        console.log('o')
        console.log("in_hour")
        createConversion(bill["oMEJkCfYvORt8uAKhb7t"])
        gtag('event', 'conversion', {
          'send_to': 'AW-11171719053/Pj0iCKCI0a8YEI3Pi88p',
          'value': bill["oMEJkCfYvORt8uAKhb7t"]["price"],
          'currency': 'TWD',
          'transaction_id': bill["oMEJkCfYvORt8uAKhb7t"]["order_id"]
        });
        gtag('event', '購買_20天全面了解python');
      }
    }
    
  }else if(to.path == '/courses/WQgj9SntAQ5Vwrk7QpSB/lectures'){
    // 全面了解機器學習
    // console.log('w')
    if(python_ai_package){
      BuyPythonAiPackage()
    }else{
      if(buyInHour("WQgj9SntAQ5Vwrk7QpSB")){
        console.log("w")
        console.log("in_hour")
        createConversion(bill["WQgj9SntAQ5Vwrk7QpSB"])
        gtag('event', 'conversion', {
          'send_to': 'AW-11171719053/xGXnCLrX0K8YEI3Pi88p',
          'value': bill["WQgj9SntAQ5Vwrk7QpSB"]["price"],
          'currency': 'TWD',
          'transaction_id': bill["WQgj9SntAQ5Vwrk7QpSB"]["order_id"]
        }); 
        gtag('event', '購買_全面了解機器學習');
      }
      
    }
  }else if(to.path == '/courses/MwUa9KNrgEgGPe8qMHiu/lectures'){
    // 踹開新世界的大門
    // console.log('m')
    if(python_ai_package){
      BuyPythonAiPackage()
    }else{
      if(buyInHour("MwUa9KNrgEgGPe8qMHiu")){
        console.log("m")
        console.log("in_hour")
        createConversion(bill["MwUa9KNrgEgGPe8qMHiu"])
        gtag('event', 'conversion', {
          'send_to': 'AW-11171719053/SY-jCL3X0K8YEI3Pi88p',
          'value': bill["MwUa9KNrgEgGPe8qMHiu"]["price"],
          'currency': 'TWD',
          'transaction_id': bill["MwUa9KNrgEgGPe8qMHiu"]["order_id"]
        });
        gtag('event', '購買_踹開新世界的大門');
      }
    }
  }else if(to.path == '/courses/dt55yF40LajQwAQDS6uV/lectures'){
    // 全面了解 HTML & CSS
    if(web_package){
      BuyWebPackage()
    }else{
      if(buyInHour("dt55yF40LajQwAQDS6uV")){
        console.log("in_hour")
        createConversion(bill["dt55yF40LajQwAQDS6uV"])
        gtag('event', 'conversion', {
          'send_to': 'AW-11171719053/Q3w8CLDrn6MZEI3Pi88p',
          'value': bill["dt55yF40LajQwAQDS6uV"]["price"],
          'currency': 'TWD',
          'transaction_id': bill["dt55yF40LajQwAQDS6uV"]["order_id"]
        });
        gtag('event', '購買_全面了解HTMLCSS');
      }
    }
  }else if(to.path == '/courses/n652vpHnayrTC8odrPrc/lectures'){
    // 全面了解 Javascript
    if(web_package){
      BuyWebPackage()
    }else{
      if(buyInHour("n652vpHnayrTC8odrPrc")){
        console.log("in_hour")
        createConversion(bill["n652vpHnayrTC8odrPrc"])
        gtag('event', 'conversion', {
          'send_to': 'AW-11171719053/NnFACLPrn6MZEI3Pi88p',
          'value': bill["n652vpHnayrTC8odrPrc"]["price"],
          'currency': 'TWD',
          'transaction_id': bill["n652vpHnayrTC8odrPrc"]["order_id"]
        });
        gtag('event', '購買_全面了解Javascript');
      }
    }
  }else if(to.path == '/courses/XPhtUjjYq2JROhWWM2Hb/lectures'){
    // 全面了解 Java
    if(buyInHour("XPhtUjjYq2JROhWWM2Hb")){
      console.log("in_hour")
      createConversion(bill["XPhtUjjYq2JROhWWM2Hb"])
      gtag('event', 'conversion', {
        'send_to': 'AW-11171719053/MSIvCLnrn6MZEI3Pi88p',
        'value': bill["XPhtUjjYq2JROhWWM2Hb"]["price"],
        'currency': 'TWD',
        'transaction_id': bill["XPhtUjjYq2JROhWWM2Hb"]["order_id"]
      });
      gtag('event', '購買_全面了解Java');
    }
  }
})

export default router;